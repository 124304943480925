import { BaseModuleWithAppName } from "../../../controller/Module"
import merge from "lodash/merge"

export interface ConversionMap {
  map: ConversionMapRoot
}

export interface ConversionMapRoot {
  [key: string]: ConversionMapCollection
}

export interface ConversionMapCollection {
  [key: string]: any
  __api?: ApiDescription
  __noDeleteCascade?: boolean
}

export interface ApiDescription {
  get?: ApiMethodDescription
  list?: ApiMethodDescription
  view?: ApiMethodDescription
  create?: ApiMethodDescription
  update?: ApiMethodDescription
  updateMany?: ApiMethodDescription
  remove?: ApiMethodDescription
  invalidateUnload?: InvalidationRules
  documentType?: string
  invalidateRefreshWhenUsed?: InvalidationRules
  invalidateRefreshWhenUsedDelay?: number
}

export interface InvalidationRules {
  // Collection (document type)
  [key: string]: InvalidationRule
}

export interface InvalidationRule {
  // Action
  [key: string]: boolean
}

export type Segment =
  | string
  | {
      name: string
      default?: string
      // Default is true for segments
      required?: boolean
    }
export type Search = {
  name: string // Name of the search parameter in URL and in the given parameters dictionary if paramName is not specified
  paramName?: string // Name of the search parameter in the parameters dictionary. Defaults to name
  default?: any // Default search parameter value if no value is found in properties
  required?: boolean // An error is produced if set to true and no parameter is provided and default isn't set
}
export type ImportType = "document" | "view" | "array"
export type ExportType = "document" | "array"

export interface RequestBodyDescription {
  document_type?: string // Overrides type provided in method
  exportType?: ExportType
}

export interface ResponseBodyDescription {
  document_type?: string // Overrides type provided in method
  importType?: ImportType
  viewName?: string // Relevant if import type is view
}

export interface ApiMethodDescription {
  segments?: (string | Segment)[]
  search?: Search[]
  body?: Search[]
  requestBody?: RequestBodyDescription
  responseBody?: ResponseBodyDescription
  method?: "get" | "post" | "put" | "delete" | "patch"
  invalidateViews?: string[] | "all"
}

export class ConversionMapModule extends BaseModuleWithAppName implements ConversionMap {
  get map(): any {
    return this._map
  }

  declare conversionMapSources: ConversionMap[]

  get moduleName() {
    return "ConversionMap"
  }

  get dependencies() {
    return ["ConversionMapSource"]
  }

  private _map: any

  setup() {
    const sourceMaps = []

    for (const sourceModule of this.conversionMapSources) {
      sourceMaps.push(sourceModule.map)
    }

    this._map = {}

    if (sourceMaps.length === 1) {
      this._map = sourceMaps[0]
    } else {
      this._map = merge.apply(this, sourceMaps) as any[]
    }
  }

  private extend(...args) {
    const result = {}
    let obj

    for (let i = 0; i < args.length; i++) {
      obj = args[i]
      for (const key of Object.keys(obj)) {
        if (Object.prototype.toString.call(obj[key]) === "[object Object]") {
          if (typeof result[key] === "undefined") {
            result[key] = {}
          }

          result[key] = this.extend(result[key], obj[key])
        } else {
          result[key] = obj[key]
        }
      }
    }

    return result
  }
}
