import ReactMarkdown from "react-markdown"
import remarkGfm from "remark-gfm"

import { ViewComponentProps } from "core/components/base/ViewComponent"

import { ViewComponent } from "../../../../app/insights_admin/components/base/ViewComponent"

interface Props extends ViewComponentProps {
  content: string
}

export default class Markdown extends ViewComponent<Props, {}> {
  get componentName(): string[] {
    return ["admin_panel", "ui", "Markdown"]
  }

  constructor(props) {
    super(props)
  }

  render() {
    const { content } = this.props

    return <ReactMarkdown remarkPlugins={[remarkGfm]}>{content}</ReactMarkdown>
  }
}
