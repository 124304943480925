import { InputBase } from "@material-ui/core"
import FormControl from "@material-ui/core/FormControl"
import MenuItem from "@material-ui/core/MenuItem"
import Select from "@material-ui/core/Select"
import { Theme, createStyles, withStyles } from "@material-ui/core/styles"
import classNames from "classnames"

import { Glyph } from "../symbols"
import BaseInput, { BaseInputProps, BaseInputState } from "./BaseInput"

const styles = ({ transitions }: Theme) =>
  createStyles({
    formLabel: {
      fontSize: 18,
      whiteSpace: "nowrap",
      fontStyle: "normal"
    },
    margin: {
      marginTop: "0.5rem",
      marginBottom: "0.5rem"
    },
    group: {
      marginTop: "1rem"
    },
    emptySelection: {
      minHeight: "1.5rem"
    },
    fullWidth: {
      width: "100%"
    },
    root: {
      "label + &": {
        marginTop: "2rem"
      }
    },
    menuGutter: {},
    bootstrapInput: {
      borderRadius: 4,
      fontSize: 16,
      padding: "6px 24px 6px 12px",
      transition: transitions.create(["border-color", "box-shadow"]),
      "&:focus": {
        borderColor: "#b88b5d",
        boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)"
      }
    },
    defaultColor: {
      backgroundColor: DropDown.theme.colors.faintColor.toString()
    },
    alternativeColor: {
      backgroundColor: DropDown.theme.colors.windowControlBackgroundColor.toString()
    },
    selectedMultiItem: {
      justifyContent: "space-between"
    }
  })

export interface DropdownOption {
  label: string
  value?: string
  disabled?: boolean
}

interface DropdownProps extends BaseInputProps<string | string[]> {
  options: DropdownOption[]
  placeholder?: string
  variant?: "auto" | "mui"
  disabled?: boolean
  multiple?: boolean
  allowEmptySelection?: boolean
  emptySelectionLabel?: string
  required?: boolean
  fullWidth?: boolean
  useAlternativeColor?: boolean
  width?: string
  margin?: boolean
  error?: boolean
  context: any
  classes: any
  dropdownClass?: string
  renderValue?: (value: any) => JSX.Element
  onOpen?: () => void
  onClose?: () => void
  onChange: (value: string, context?: any, event?) => void
  onChangeMultiple: (values: string[], context?: any, event?) => void
}

interface DropdownState extends BaseInputState {
  open?: boolean
}

class DropDown extends BaseInput<DropdownProps, DropdownState> {
  get componentName() {
    return ["ui", "form", "Dropdown"]
  }

  constructor(props) {
    super(props)

    this.state = {
      open: false
    }
  }

  render() {
    const { classes, margin, fullWidth, error, options, width, variant, className } = this.props

    return (
      <FormControl
        error={error}
        className={classNames(
          className,
          classes.formControl,
          fullWidth && classes.fullWidth,
          margin !== false && classes.margin
        )}
        style={{
          width: width
        }}
      >
        {this.renderLabel()}
        {this.renderMuiSelect()}
      </FormControl>
    )
  }

  private renderMuiSelect() {
    const { classes, dropdownClass, multiple, value, renderValue, placeholder, disabled, onOpen, onClose } = this.props
    const { open } = this.state
    return (
      <Select
        // Fix a re-render bug in MUI
        key={Math.random().toString()}
        disabled={disabled}
        value={multiple ? value || [""] : value ? value.toString() : ""}
        multiple={multiple}
        displayEmpty={!!placeholder}
        onChange={this.onChange}
        required={this.props.required}
        className={classNames(dropdownClass)}
        classes={{
          select: classes.select
        }}
        input={
          <InputBase className={this.inputBaseClass} classes={{ input: this.inputClass, root: this.inputBaseClass }} />
        }
        renderValue={renderValue}
        open={open}
        onOpen={() => {
          if (onOpen) {
            onOpen()
            return
          }

          this.setState({ open: true })
        }}
        onClose={() => {
          if (onClose) {
            onClose()
            return
          }

          this.setState({ open: false })
        }}
      >
        {this.renderMuiOptions()}
      </Select>
    )
  }

  private renderMuiOptions() {
    const { placeholder, classes, value, multiple, allowEmptySelection, emptySelectionLabel } = this.props

    const options = []

    if (placeholder)
      options.push(
        <MenuItem key="placeholder" disabled value="">
          {placeholder}
        </MenuItem>
      )

    if (allowEmptySelection)
      options.push(
        <MenuItem key="empty" value="">
          <em className={classes.emptySelection}>{emptySelectionLabel ?? ""}</em>
        </MenuItem>
      )

    for (const option of this.props.options) {
      const multiSelected = multiple && value?.indexOf(option.value) !== -1
      const optionValue = option.value ? option.value.toString() : multiple ? [""] : ""
      options.push(
        <MenuItem
          key={option.value}
          value={optionValue}
          disabled={option.disabled}
          className={classNames(multiple && value?.indexOf(option.value) !== -1 && classes.selectedMulti)}
          classes={{
            gutters: classes.menuGutter,
            root: multiSelected && classes.selectedMultiItem
          }}
        >
          {option.label}
          {multiSelected && <Glyph glyphType="materialize" glyph="done" glyphClass={undefined} />}
        </MenuItem>
      )
    }

    return options
  }

  private onChange = event => {
    const { onChange, onChangeMultiple, context, multiple, value } = this.props

    this.setState({ open: false })

    if (multiple) {
      const newValues = event.target.value

      if (onChange) {
        let clickedOption
        if (newValues?.length > value?.length) {
          clickedOption = newValues[newValues.length - 1]
        } else if (newValues?.length < value?.length) {
          clickedOption = (value as string[]).find(option => newValues.indexOf(option) === -1)
        }

        onChange(clickedOption, context, event)
      }

      if (onChangeMultiple) {
        onChangeMultiple(event.target.value, context, event)
      }
    } else if (onChange) {
      onChange(event.target.value, context, event)
    }
  }
}

export default withStyles(styles)(DropDown)
