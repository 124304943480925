import {
  ViewComponent as BaseViewComponent,
  ViewComponentProps,
  ViewComponentState
} from "core/components/base/ViewComponent"

import { appName } from "../../AppName"

export abstract class ViewComponent<
  P extends ViewComponentProps,
  S extends ViewComponentState | void = {}
> extends BaseViewComponent<P, S> {
  get appName() {
    return appName
  }
}
