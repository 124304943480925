import { Menu, MenuItem } from "@material-ui/core"
import classNames from "classnames"
import { style } from "typestyle"

import { LogicComponentProps, LogicComponentState } from "core/components/base/LogicComponent"
import Glyph from "lib/ui/components/symbols/Glyph"

import { LogicComponent } from "../base/LogicComponent"

interface LanguageSelectorProps extends LogicComponentProps {
  id: string
  language: string | undefined
  variant?: "default" | "light"
  setLanguage: (language: string) => void
  className?: string
}

interface LanguageSelectorState extends LogicComponentState {
  anchorEl?: HTMLElement
}

export default class LanguageSelector extends LogicComponent<LanguageSelectorProps, LanguageSelectorState> {
  get componentName(): string[] {
    return ["settings", "LanguageSelector"]
  }

  constructor(props) {
    super(props)

    this.state = {}
  }

  render() {
    return (
      <>
        {this.renderLanguage()}
        {this.renderMenu()}
      </>
    )
  }

  private renderLanguage() {
    super.render()
    const { variant, className } = this.props

    const containerClass = style({
      display: "flex",
      cursor: "pointer",
      alignItems: "center",
      color: variant === "light" ? this.theme.colors.fontLightColor.toString() : this.theme.colors.baseGray.toString(),
      $nest: {
        "&:hover": {
          color:
            variant === "light"
              ? this.theme.colors.fontLightColor.lighten("20%").toString()
              : this.theme.colors.baseGray.darken("50%").toString(),
          transition: "color 0.2s ease"
        }
      }
    })

    const languageNameClass = style({
      marginRight: "0.2em"
    })

    const glyphContainerClass = style({
      width: "1.2em"
    })

    return (
      <div className={classNames(className, containerClass)} onClick={this.onClick}>
        <div className={languageNameClass}>{this.txt("languages", this.props.language, "short_name")}</div>
        <div className={glyphContainerClass}>
          <Glyph glyphType="materialize" glyph="expand_more" />
        </div>
      </div>
    )
  }

  private renderMenu() {
    const { anchorEl } = this.state

    return (
      <Menu id={`${this.props.id}_menu`} anchorEl={anchorEl} open={!!anchorEl} onClose={this.onMenuClose}>
        {this.renderMenuItems()}
      </Menu>
    )
  }

  private renderMenuItems(): JSX.Element[] {
    const base = this
    const languages = this.localization.enabledLanguages

    return languages.map((language: string) => {
      const title = `${base.txt("languages", language, "name")} (${base.txt("languages", language, "short_name")})`
      const selected: boolean = base.props.language === language

      return (
        <MenuItem key={title} selected={selected} onClick={() => this.onMenuSelect(language)}>
          {title}
        </MenuItem>
      )
    })
  }

  private onClick = event => {
    const target = event.currentTarget
    this.updateState(state => (state.anchorEl = target))
  }

  private onMenuSelect = value => {
    this.props.setLanguage(value)

    this.onMenuClose(event)
  }

  private onMenuClose = event => {
    this.updateState(state => (state.anchorEl = undefined))
  }
}
