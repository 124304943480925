import type {
  GridContentAlignment,
  GridDirection,
  GridItemsAlignment,
  GridJustification,
  GridSize,
  GridSpacing,
  GridWrap
} from "@material-ui/core/Grid/Grid"
import MuiGrid from "@material-ui/core/Grid/Grid"
import { Theme, createStyles, withStyles } from "@material-ui/core/styles"
import type { PropsWithChildren } from "react"

import type { ViewComponentProps } from "core/components/base/ViewComponent"
import { ViewComponent } from "../../base/ViewComponent"

const styles = ({}: Theme) => createStyles({})

export interface GridProps extends ViewComponentProps {
  alignContent?: GridContentAlignment
  alignItems?: GridItemsAlignment
  direction?: GridDirection
  container?: boolean
  justifyContent?: GridJustification
  spacing?: GridSpacing
  wrap?: GridWrap
  xs?: GridSize
  sm?: GridSize
  md?: GridSize
  lg?: GridSize
  xl?: GridSize
  className?: string
  spacer?: boolean
  spacerSize?: number
}

class Grid extends ViewComponent<PropsWithChildren<GridProps>> {
  render() {
    const {
      children,
      alignContent,
      alignItems,
      direction,
      container,
      justifyContent,
      spacing,
      wrap,
      xs,
      sm,
      md,
      lg,
      xl,
      className,
      spacer,
      spacerSize
    } = this.props

    return (
      <MuiGrid
        style={{ height: spacer ? `${spacerSize ?? 2}rem` : "auto" }}
        className={className}
        alignContent={alignContent}
        alignItems={alignItems}
        direction={direction}
        item={!container}
        container={container}
        justifyContent={justifyContent}
        spacing={spacing}
        wrap={wrap}
        xs={spacer ? 12 : xs}
        sm={sm}
        md={md}
        lg={lg}
        xl={xl}
      >
        {children}
      </MuiGrid>
    )
  }
}

export default withStyles(styles)(Grid)
